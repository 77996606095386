<div class="rounded-lg fixActionRow">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">{{title}}</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
    <form class="grid grid-cols-2 gap-4 gap-x-10 mt-2 pb-3 w-[100%]" [formGroup]="enrollmentForm">

      <div class="select-input flex-col justify-center items-center">
        <drdp-select-state
          [initialStateId]="stateId.value"
          (state)="handleDropdown($event, selectType.States)"
          [isValid]="!stateId.touched || stateId.valid"
          [required]="true">
        </drdp-select-state>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-agency
          [initialAgencyId]="agencyId.value"
          (agency)="handleDropdown($event, selectType.Agencies)"
          [stateId]="stateId.value"
          [required]="true"
        >
        </drdp-select-agency>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-age-grade
              #ageGroupInstrumentSelect
              [initialageGradeId]="ageGradeId.value"
              (ageGrade)="handleDropdown($event, selectType.AgeInstrument)"
              [stateId]="stateId.value"
              [multiSelect]="false"
              [required]="true"
              label="Child Age/Grade Instrument"
              [isPilot]="isPilotEnroll"
            >
            </drdp-select-age-grade>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-date-picker
                label="Agency Enrollment Date"
                [initialDate]="enrollmentStartDate?.value"
                [isReadOnly]="false"
                [isRequired]="true"
                (emitDate)="handleDate($event, 'enrollmentStartDate')"
              >
        </drdp-date-picker>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-site
          [initialSiteId]="siteId.value"
          (site)="handleDropdown($event, selectType.Sites)"
          [agencyId]="agencyId.value"
          [multiSelect]="false"
          [multiSelectInitVals]="false"
          [required]="true"
          [isDualEnrollments]="isDualEnrollment"
        >
        </drdp-select-site>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-rating-period
          [initialRatingPeriodId]="ratingPeriodId.value"
          (ratingPeriod)="handleDropdown($event, selectType.RatingPeriods)"
          [agencyId]="agencyId.value"
          [required]="true"
          [displayCurrentRps]="true">
          </drdp-select-rating-period>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-class
            (classroom)="handleDropdown($event, selectType.Class)"
            [siteIds]="siteId.value"
            [required]="true"
            [ratingPeriodId]="ratingPeriodId.value"
            [multiSelect]="false"
            [showPilotClasses]="isPilotEnroll">
        </drdp-select-class>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <div class="form-input-label-group">
          <label class="font-normal required" for="enrollmentPrograms"
            >Program Type</label
          >
          <mat-form-field appearance="fill" class="drdp-input">
            <mat-select
              formControlName="enrollmentProgramIds"
              [placeholder]="enrollmentProgramPlaceholder"
              multiple
              required="true"
              [disabled]="!enrollmentProgramTypesFiltered"
            >
              <mat-option
                *ngFor="let ept of enrollmentProgramTypesFiltered"
                [value]="ept.enrollmentProgramId"
              >
                {{ ept.enrollmentProgramType?.enrollmentProgramName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="enrollmentProgramIds.invalid"
              >{{ 'Choose valid Enrollment Program'  }}</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-iep-plan
              [initialId]="iep.value"
              [required]="true"
              (optionSelected)="handleDropdown($event, selectType.IepPlan)"
              [disabled]="isPilotEnroll"
            >
            </drdp-select-iep-plan>
      </div>

      <div *ngIf="!isNewHampshire" class="col-span-1 items-center w-[100%]">
        <drdp-select-selpa
              (selpa)="handleDropdown($event, selectType.Selpa)"
              additionalClasses="pb-7"
              [initialSelpaId]="selpaId.value"
              [disabled]="isPilotEnroll"
            >
            </drdp-select-selpa>
      </div>

      <div *ngIf="isNewHampshire || isPilotEnroll" class="col-span-1 items-center w-[100%]">
        <label class="font-normal" [ngClass]="{'mb-7': isNewHampshire}" for="primaryDisabilityId">Primary Disability</label>
        <mat-form-field appearance="fill" class="drdp-input">
          <mat-select 
            formControlName="primaryDisabilityId" 
            [placeholder]="selectedDisability ?? 'Primary Disability'"
            (selectionChange)="handlePrimaryDisability($event)"
          >
            <mat-option 
              *ngFor="let disability of disabilities" 
              [value]="disability"
              >{{ disability.primaryDisabilityName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="hasFollowUp" class="form-input-label-group">
        <div class="col-span-1 col-end-3">
          <label class="font-normal" for="extensiveSupportId">Does the child have extensive support needs?</label>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="extensiveSupportId"
          >
            <mat-radio-button
              color="primary"
              *ngFor="let es of extensiveSupports"
              [value]="es.id"
            >
              {{ es.extensiveSupportOption }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal" for="adaptations">Adaptations</label>
            <mat-form-field appearance="fill" class="drdp-input">
              <mat-select
                formControlName="adaptationIds"
                multiple
                placeholder="Choose all that apply"
              >
                <mat-option
                  *ngFor="let adaptation of adaptations"
                  [value]="adaptation.id"
                  [disabled]="isPilotEnroll"
                  >{{ adaptation.adaptationTypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
      </div>

      <div *ngIf="isNewHampshire" class="col-span-1 items-center w-[100%]">
        <label class="font-normal" for="districtOfLiabilityId" 
          [ngClass]="{'required': iep.value == iepPlan.Yes}">
          District of Liability
        </label>
        <mat-form-field appearance="fill" class="drdp-input">
          <mat-select formControlName="districtOfLiabilityId" placeholder="District of Liability">
            <mat-option *ngFor="let dol of districtsOfLiability" [value]="dol.id">
              {{ dol.districtOfLiabilityName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="isNewHampshire" class="col-span-1 items-center w-[100%]">
        <drdp-date-picker
          label="Special Education Entry Date"
          [isReadOnly]="false"
          labelClasses="mb-2"
          (emitDate)="handleDate($event, 'seEntryDate')"
        >
        </drdp-date-picker>
      </div>

      <div *ngIf="isNewHampshire" class="col-span-1 items-center w-[100%]">
        <drdp-date-picker
          label="Special Education Anticipated Exit Date"
          [isReadOnly]="false"
          labelClasses="mb-2"
          (emitDate)="handleDate($event, 'seAnticipateExitDate')"
        >
        </drdp-date-picker>
      </div>

      <div *ngIf="isNewHampshire" class="col-span-1 items-center w-[100%]">
        <drdp-select-initial-assessment-tool-type
          additionalClasses="pb-0"
          label="Initial Assessment Tool"
          (optionSelected)="handleDropdown($event, 'initialAssessmentToolType')">
        </drdp-select-initial-assessment-tool-type>
      </div>

      <div *ngIf="isNewHampshire" class="col-span-1 items-center w-[100%]">
        <drdp-select-outcome
          label="Outcome 1"
          (optionSelected)="handleDropdown($event, 'outcome1')">
        </drdp-select-outcome>
      </div>

      <div *ngIf="isNewHampshire" class="col-span-1 items-center w-[100%]">
        <drdp-select-outcome
          label="Outcome 2"
          (optionSelected)="handleDropdown($event, 'outcome2')">
        </drdp-select-outcome>
      </div>

      <div *ngIf="isNewHampshire" class="col-span-1 items-center w-[100%]">
        <drdp-select-outcome
          label="Outcome 3"
          (optionSelected)="handleDropdown($event, 'outcome3')">
        </drdp-select-outcome>
      </div>
    </form>

  </div>
  <div class="spacer"></div>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <drdp-btn color="cancel" class="mr-2" mat-button mat-dialog-close (click)="cancel()">Cancel</drdp-btn>
    <drdp-btn mat-button [disabled]="enrollmentForm.invalid || isProcessing" cdkFocusInitial
      (click)="onSubmit()">{{ submitBtnText }}</drdp-btn>
  </div>
</div>
