import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '@core/enums/roles';
import { ILoginRequest } from '@core/interfaces/ilogin-request';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { ILoginSession } from '@core/interfaces/ilogin-session';
import { IRoleAssignment } from '@core/interfaces/iroles';
import { environment } from '@env/environment';
import { Observable, tap } from 'rxjs';
import { DataService } from './data.service';
import { ChatboxService } from './chatbox.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authToken = environment.authToken;
  private readonly userInfo = 'drdp-user';
  private jwtHelper = new JwtHelperService();

  constructor(private dataService: DataService, private router: Router, private chatbox: ChatboxService) {}

  login(user: ILoginRequest, message?: string): Observable<any> {
    return this.dataService.post('auth/login', user, message).pipe();
  }

  logout(): Observable<any> {
    const currentUser = this.getCurrentUser();
    return this.dataService
      .post(
        `auth/logout?userId=${currentUser.userId}&loginAuditId=${currentUser.loginAuditId}`,
        {},
        'Logout Successful'
      )
      .pipe(
        tap((res: boolean) => {
          this.chatbox.hideChatboxWidget();
          localStorage.clear();
          this.router.navigateByUrl('login');
        })
      );
  }

  checkLogin(): boolean {
    let result = false;
    const token = localStorage.getItem(this.authToken);
    if (token) {
      result = !this.jwtHelper.isTokenExpired(token);
    }
    return result;
  }

  forgotPassword(email: string, message?: string): Observable<any> {
    return this.dataService.post(
      `Auth/Forgot-Password`,
      { email: email},
      message
    );
  }

  changePassword(payload: any, message?: string): Observable<any> {
    return this.dataService.put(
      `Auth/Password?password=${payload.newPassword}&guid=${payload.guid}`,
      payload,
      message
    );
  }

  updatePassword(payload: any, message?: string): Observable<any> {
    return this.dataService
      .post('auth/update-password', payload, message);
  }

  adminResetPassword(userId: number, message?: string): Observable<boolean> {
    return this.dataService.put(
      `auth/admin-reset-password/user/${userId}`,
      message
    );
  }

  getActiveSession(message?: string): Observable<ILoginSession> {
    const user = JSON.parse(localStorage.getItem(this.userInfo) ?? '{}');
    return this.dataService.get(
      `auth/session/loginAudit/${user.loginAuditId}`,
      message
    );
  }

  deleteOtherSession(payload: any): Observable<boolean> {
    return this.dataService.delete(
      `auth/session?userId=${payload.userId}&loginAuditId=${payload.loginAuditId}`
    );
  }

  captureUserInfo(loginRes: ILoginResponse): void {
    localStorage.setItem(this.authToken, JSON.stringify(loginRes.token));
    localStorage.setItem(this.userInfo, JSON.stringify(loginRes));
  }

  getCurrentUser(): ILoginResponse {
    return JSON.parse(localStorage.getItem(this.userInfo) ?? '{}');
  }

  getUserStateId(): number {
    const { stateId } = this.getCurrentUser();
    return stateId;
  }

  getUserStateCode(): string {
    const { stateCode } = this.getCurrentUser();
    return stateCode;
  }

  getUserPermissions(): number[] {
    const user: ILoginResponse = JSON.parse(
      localStorage.getItem(this.userInfo) ?? '{}'
    );

    return user.permissions ?? [];
  }

  isSuperUser(): boolean {
    let userInfo = this.getCurrentUser();
    return userInfo.roleAssignments.some(
      (x: IRoleAssignment) =>
        x.roleId === Role.SystemAdministrator ||
        x.roleId === Role.CustomerService
    );
  }

  isSuperAdmin(): boolean {
    let userInfo = this.getCurrentUser();
    return userInfo.roleAssignments.some(
      (x: IRoleAssignment) => x.roleId === Role.SystemAdministrator
    );
  }

  isCustomerService(): boolean {
    let userInfo = this.getCurrentUser();
    return userInfo.roleAssignments.some(
      (x: IRoleAssignment) => x.roleId === Role.CustomerService
    );
  }

  isAdmin(): boolean {
    let userInfo = this.getCurrentUser();
    return userInfo.roleAssignments.some(
      (x: IRoleAssignment) =>
        x.roleId === Role.AgencyAdministrator ||
        x.roleId === Role.LeadAgencyAdministrator ||
        x.roleId === Role.SiteAdministrator
    );
  }

  isLeadAgencyAdmin(): boolean {
    let userInfo = this.getCurrentUser();
    return userInfo.roleAssignments.some(
      (x: IRoleAssignment) => x.roleId === Role.LeadAgencyAdministrator
    );
  }

  isAgencyAdmin(): boolean {
    let userInfo = this.getCurrentUser();
    return userInfo.roleAssignments.some(
      (x: IRoleAssignment) => x.roleId === Role.AgencyAdministrator
    );
  }

  isSiteAdmin(): boolean {
    let userInfo = this.getCurrentUser();
    return userInfo.roleAssignments.some(
      (x: IRoleAssignment) => x.roleId === Role.SiteAdministrator
    );
  }

  isTeacher(): boolean {
    let userInfo = this.getCurrentUser();
    return userInfo.roleAssignments.some(
      (x: IRoleAssignment) =>
        x.roleId == Role.Teacher || x.roleId == Role.TeacherOfRecord
    );
  }

  isValidIdentifier(guid: string) {
    return this.dataService.get(`auth/is-valid-identifier/${guid}`);
  }

  getAccessLevel() {
    var accessLevel = 0;

    if (this.getCurrentUser()) accessLevel = this.getCurrentUser().accessLevel;

    return accessLevel;
  }

  recaptchaVerify(token: string): Observable<any> {
    return this.dataService.post('auth/recaptcha', { token: token});
  }
}
