<div id="teacher-survey-modal" class="rounded-lg fix-action-row modal-body">
  <div class="flex justify-between items-start">
    <div>
      <p class="font-semibold pb-1">DRDP Pilot Study Measure Feedback</p>
      <p class="mt-1 pb-1">Survey questions must be completed in order for your final measure rating to be saved.</p>
    </div>
    <drdp-btn *ngIf="!dialogData.viewOnly" color="save" class="ml-5" type="submit" (click)="onSubmit()">Save</drdp-btn>
    <i *ngIf="dialogData.viewOnly" mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>
  <hr class="w-full">
  <div mat-dialog-content>
    <form [formGroup]="difficultyForm">
      <mat-radio-group formControlName="difficultyLevelId">
        <label class="text-lg required">
          How easy or difficult was it to rate this measure for this child?
        </label>
        <mat-label>Select Difficulty Level:</mat-label>
        <div *ngFor="let level of difficultyLevels">
          <ng-container *ngIf="level.isDefault">
            <mat-radio-button
              [value]="level.id"
              color="primary">
              {{level.name}}
            </mat-radio-button>
          </ng-container>
        </div>
      </mat-radio-group>
      <mat-radio-group *ngIf="this.dialogData.teacherSurveyReq?.isStructuredPrompt || this.dialogData?.isStructuredPrompt" formControlName="extentLevelId">
        <label class="text-lg required">
          To what extent did the structured prompt assist you in rating this measure for this child?
        </label>
        <mat-label>Select Difficulty Level:</mat-label>
        <div *ngFor="let level of difficultyLevels">
          <ng-container *ngIf="isPTKMathAndNotDefault(level)">
            <mat-radio-button
              [value]="level.id"
              color="primary">
              {{level.name}}
            </mat-radio-button>
          </ng-container>
        </div>
      </mat-radio-group>

      <label class="text-lg">
        Describe any difficulties you encountered in rating this measure for this child.
      </label>
      <textarea 
          formControlName="difficultyAnswer"
          name="difficultyAnswer" 
          id="difficultyAnswer" 
          class="w-full border border-black rounded-md p-2"
          placeholder="Please leave your reply here."
        >
      </textarea>
    </form>
  </div>
</div>