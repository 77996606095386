import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgeGradeInstrument } from '@core/enums/age-grade-instruments';
import { EnrollmentProgram } from '@core/enums/enrollment-programs';
import { IepPlan } from '@core/enums/iep-plan';
import { SelectType } from '@core/enums/select';
import { States } from '@core/enums/states';
import { StudentDemographic } from '@core/enums/student-demographic-form';
import { IAdaptationType } from '@core/interfaces/iadaptation-type';
import { IAgeGradeEnrollmentProgramFilter } from '@core/interfaces/iage-grade-instrument';
import { IDisability } from '@core/interfaces/idisability';
import { IDistrictOfLiability } from '@core/interfaces/idistrict-of-liability';
import { IDualEnrollModalData } from '@core/interfaces/idual-enroll-modal-data';
import { IExtensiveSupport } from '@core/interfaces/iextensive-support';
import { IStudentDualEnrollment } from '@core/interfaces/istudent';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { StateService } from '@core/services/state.service';
import { StudentService } from '@core/services/student.service';
import { ToastService } from '@core/services/toast.service';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-dual-enroll-child',
  templateUrl: './dual-enroll-child.component.html',
  styleUrls: ['./dual-enroll-child.component.scss'],
})
export class DualEnrollChildComponent implements OnInit {
  enrollmentForm: FormGroup | any;
  isProcessing = false;
  userStateId: number = this.userService.getUserStateId();
  userAgencyId: number = this.userService.getCurrentUser().agencyId;
  adaptations: IAdaptationType[] = [];
  enrollmentProgramPlaceholder: string = 'Select Enrollment Programs';
  enrollmentProgramTypes!: IAgeGradeEnrollmentProgramFilter[];
  enrollmentProgramTypesFiltered!: IAgeGradeEnrollmentProgramFilter[];
  AgeGradeInstrument = AgeGradeInstrument;
  isNewHampshire = this.userStateId === States.newHampshire;
  disabilities: IDisability[] = [];
  studentDemographics = StudentDemographic;
  iepPlan = IepPlan;
  districtsOfLiability: IDistrictOfLiability[] = [];
  isPilotEnroll: boolean = this.data.action === 'pilotEnroll';
  isDualEnrollment: boolean = this.data.action === 'dualEnroll';
  submitBtnText: string = this.isPilotEnroll ? 'Complete Pilot Enrollment' : 'Complete Dual Enrollment';
  title: string = this.isPilotEnroll ? 'Add to 2025 Pilot Classroom' : 'Request for Dual Enrollment';
  studentInfo: any = this.data.data;
  hasFollowUp: boolean = false;
  extensiveSupports: IExtensiveSupport[] = [];
  selectedDisability?: string;

  public get selectType() {
    return SelectType;
  }

  public get programTypeValues() {
    return EnrollmentProgram;
  }

  get stateId() {
    return this.enrollmentForm.get('stateId');
  }
  get agencyId() {
    return this.enrollmentForm.get('agencyId');
  }
  get ageGradeId() {
    return this.enrollmentForm.get('ageGradeId');
  }
  get enrollmentStartDate() {
    return this.enrollmentForm.get('enrollmentStartDate');
  }
  get siteId() {
    return this.enrollmentForm.get('siteId');
  }
  get ratingPeriodId() {
    return this.enrollmentForm.get('ratingPeriodId');
  }
  get classId() {
    return this.enrollmentForm.get('classId');
  }
  get enrollmentProgramIds() {
    return this.enrollmentForm.get('enrollmentProgramIds');
  }
  get iep() {
    return this.enrollmentForm.get('iep');
  }
  get selpaId() {
    return this.enrollmentForm.get('selpaId');
  }
  get adaptationIds() {
    return this.enrollmentForm.get('adaptationIds');
  }
  get primaryDisabilityId() {
    return this.enrollmentForm.get('primaryDisabilityId');
  }
  get districtOfLiabilityId() {
    return this.enrollmentForm.get('districtOfLiabilityId');
  }
  get extensiveSupportId() {
    return this.enrollmentForm.get('extensiveSupportId');
  }

  constructor(private fb: FormBuilder,
              private lookupService: LookupService,
              private userService: AuthService,
              private toastService: ToastService,
              private studentService: StudentService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DualEnrollChildComponent>) {}

  ngOnInit(): void {
    this.initializeForms();
    this.initializeData();
  }

  initializeForms(): void {
    const adaptationsIds = this.studentInfo.adaptationIds ? this.studentInfo.adaptationIds.split(',').map((id: string) => +id) : [];
    this.enrollmentForm = this.fb.group({
      stateId: [this.userStateId, [Validators.required]],
      agencyId: [this.userAgencyId, [Validators.required]],
      ageGradeId: [null, [Validators.required]],
      enrollmentStartDate: [null, [Validators.required]],
      siteId: [null, [Validators.required]],
      ratingPeriodId: [null, [Validators.required]],
      classId: [null, [Validators.required]],
      enrollmentProgramIds: [null, Validators.required],
      iep: [this.isPilotEnroll ? this.studentInfo.iepPlanId : null, [Validators.required]],
      selpaId: [this.isPilotEnroll ? this.studentInfo.selpaId : null],
      primaryDisabilityId: [null],
      adaptationIds: [this.isPilotEnroll ? adaptationsIds : []],
      seEntryDate: [null],
      seAnticipateExitDate: [null],
      initialAssessmentToolId: [null],
      outcome1Id: [null],
      outcome2Id: [null],
      outcome3Id: [null],
      districtOfLiabilityId: [null],
      extensiveSupportId: [null]
    });
  }

  initializeData(): void {
    this.lookupService.getDualEnrollModalData()
      .pipe(take(1)).subscribe((res: IDualEnrollModalData) => {
        this.enrollmentProgramTypes = res.enrollmentProgramTypes;
        this.adaptations = res.adaptations;
        this.disabilities = res.disabilities;
        this.districtsOfLiability = res.districtsOfLiability;
        this.extensiveSupports = res.extensiveSupports;
    });
  }

  handleDropdown(event: any, selectType: string): void {
    switch (selectType) {
      case SelectType.States:
        this.stateId.setValue(event?.id);        
        this.isNewHampshire = this.stateId.value === States.newHampshire;
        break;
      case this.selectType.Agencies:
        this.agencyId.setValue(event?.id);
        break;
      case this.selectType.AgeInstrument:
        this.ageGradeId.setValue(event?.id);
        this.enrollmentProgramIds.reset();
        
        this.enrollmentProgramTypesFiltered =
        this.enrollmentProgramTypes!.filter(
          (ept: IAgeGradeEnrollmentProgramFilter) => {
            return ept.ageGradeInstrumentId === this.ageGradeId.value;
          }
        );
          
        if (this.enrollmentProgramTypesFiltered.length == 0) {
          this.enrollmentProgramPlaceholder = 'No program types for Age Grade';
        } else {
          this.enrollmentProgramPlaceholder = 'Select all that apply';
        }
        break;
      case this.selectType.Sites:
        this.siteId.setValue(event?.id);
        break;
      case this.selectType.RatingPeriods:
        this.ratingPeriodId.setValue(event?.id);
        break;
      case this.selectType.Class:
        this.classId.setValue(event?.id);
        break;
      case this.selectType.IepPlan:
        this.iep.setValue(event?.id);
        if (this.isNewHampshire) {
          if (event?.id == this.iepPlan.Yes) {
            this.districtOfLiabilityId.setValidators([Validators.required]);
            this.districtOfLiabilityId.updateValueAndValidity();
          }
          else {
            this.districtOfLiabilityId.setValidators([]);
            this.districtOfLiabilityId.updateValueAndValidity();
          }
        }
        break;
      case this.selectType.Selpa:
        this.selpaId.setValue(event?.id);
        break;
      case this.selectType.InitialAssessment:
        this.enrollmentForm.get('initialAssessmentToolId').setValue(event?.id);
        break;
      case this.selectType.Outcome1:
        this.enrollmentForm.get('outcome1Id').setValue(event?.id);
        break;
      case this.selectType.Outcome2:
        this.enrollmentForm.get('outcome2Id').setValue(event?.id);
        break;
      case this.selectType.Outcome3:
        this.enrollmentForm.get('outcome3Id').setValue(event?.id);
        break;
      default:
        break;
    }
  }

  handleDate(event: any, control: string): void {
    this.enrollmentForm.get(control).setValue(event);
  }

  handlePrimaryDisability(event: any) {
    this.primaryDisabilityId.setValue(event.value.id);
    this.hasFollowUp = event.value.hasFollowUp;
    if (!this.hasFollowUp) this.extensiveSupportId.setValue(null);
    this.selectedDisability = event.value.primaryDisabilityName;
  }

  onSubmit(): any {
    if (this.enrollmentForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }
    if (this.isProcessing) return;
    const { drdpId } = this.data.data;
    this.isProcessing = true;
    const payload: IStudentDualEnrollment = {
      studentId: drdpId,
      ...this.enrollmentForm.value,
      isDualEnrollment: this.isDualEnrollment,
    };

    this.studentService.saveDualEnrollment(payload).pipe(
      take(1),
      tap((res: boolean) => {
        if (res) {
          this.toastService.success('Dual Enrollment Saved');
          this.dialogRef.close({ success: true });
        }
      })
    ).subscribe();
  }

  cancel(): void {
    this.enrollmentForm.reset();
  }
}
