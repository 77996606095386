export const environment = {
  production: true,
  apiUrl: 'https://api.drdponline.org/',
  //url: 'https://legacy.drdponline.org/',
  url: 'https://drdponline.org/',
  authToken: 'drdp-token',
  storageName: 'drdpprodstoragewst2',
  storageUrl: 'https://drdpprodstoragewst2.blob.core.windows.net/',
  encryptionKey: 'hWmZq4t7w!z$C&F)J@NcRfUjXn2r5u8x',
  recaptcha: '6LfaYkcpAAAAAJDQ4qqzTolh5XdktqOOZsC2iY4u',
  chatboxKey: '401e2f55-65a4-4b33-be6c-c3bdd87d8984',
};
