import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

declare global {
  interface Window {
    zE: any;
    zESettings?: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ChatboxService {
  private zendeskSnippetId = 'ze-snippet';
  private zendeskWidgetId = 'launcher';
  private zendeskSnippetSrc = `https://static.zdassets.com/ekr/snippet.js?key=${environment.chatboxKey}`;
  private isZendeskLoaded = false;

  injectChatBox() {
    const zendeskIframe = document.getElementById(this.zendeskWidgetId);
    const zendeskScript = document.getElementById(this.zendeskSnippetId);
  
    if (zendeskIframe || zendeskScript) {
      this.showChatboxWidget();
    } else {
      const script = document.createElement('script');
      script.id = this.zendeskSnippetId;
      script.src = this.zendeskSnippetSrc;
      script.async = true;
      script.onload = () => {
        this.isZendeskLoaded = true;
      };
      document.body.appendChild(script);
  
      window.zE = window.zE || [];
      this.executeChatboxCommand('webWidget', 'open');
    }
  }

  openChat() {
    this.injectChatBox();
  }

  private executeChatboxCommand(...args: any[]) {
    if (window.zE) {
      window.zE.push(args);
    } else {
      console.error('Zendesk widget is not available');
    }
  }

  showChatboxWidget() {
    this.showChatboxIframe();
    this.showChatboxkAdditionalElements();
  }
  
  private showChatboxIframe() {
    const zendeskIframe = document.getElementById(this.zendeskWidgetId);
    if (zendeskIframe) {
      zendeskIframe.style.display = 'block';
    }
  }
  
  private showChatboxkAdditionalElements() {
    const elements: HTMLElement[] = Array.from(document.querySelectorAll('div[style*="z-index: 999999"][style*="position: fixed"][style*="right: 16px"]'));
    elements.forEach((el: HTMLElement) => el.style.display = 'block');
  
    const zendeskElements = document.querySelectorAll('[id^="ze-snippet"], [id^="zendesk"], [id^="zd-"]');
    zendeskElements.forEach((el: Element) => (el as HTMLElement).style.display = 'block'); 
  }
  

  hideChatboxWidget() {
    this.hideChatboxIframe();
    this.hideChatboxAdditionalElements();
  }
  
  private hideChatboxIframe() {
    const zendeskIframe = document.getElementById(this.zendeskWidgetId);
    if (zendeskIframe) {
      zendeskIframe.style.display = 'none';
    }
  }
  
  private hideChatboxAdditionalElements() {
    const elements: HTMLElement[] = Array.from(document.querySelectorAll('div[style*="z-index: 999999"][style*="position: fixed"][style*="right: 16px"]'));
    elements.forEach((el: HTMLElement) => el.style.display = 'none');
  
    const zendeskElements = document.querySelectorAll('[id^="ze-snippet"], [id^="zendesk"], [id^="zd-"]');
    zendeskElements.forEach((el: Element) => (el as HTMLElement).style.display = 'none');
  }
  
}